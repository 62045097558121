import { useMemo } from 'react'

interface MenuSubItemType {
  id: string
  slug?: string
  title: string
}
interface MenuItemType {
  id?: string
  slug?: string
  title: string
  isGroup: boolean
  subItems: MenuSubItemType[]
}

interface InfoPage {
  id: string
  title: string
  slug: string
  group?: string | null
}

export const useMenuItems = (infoPages: InfoPage[]) => {
  return useMemo(
    () =>
      infoPages.reduce<MenuItemType[]>((result, page) => {
        if (!page.group) {
          result.push({
            id: page.id,
            slug: page.slug,
            title: page.title,
            isGroup: false,
            subItems: [],
          })

          return result
        }

        // if group already exists in array, append sub item to that group
        if (result.some((item) => item.title === page.group)) {
          return result.map((item) => {
            if (item.title !== page.group) {
              return item
            }

            return {
              ...item,
              subItems: [
                ...item.subItems,
                {
                  id: page.id,
                  title: page.title,
                  slug: page.slug,
                },
              ],
            }
          })
        }

        result.push({
          title: page.group,
          isGroup: true,
          subItems: [
            {
              id: page.id,
              title: page.title,
              slug: page.slug,
            },
          ],
        })

        return result
      }, []),
    [infoPages],
  )
}
