'use client'
import styled from '@emotion/styled'
import { IonIcon } from '@ionic/react'
import { AnimatePresence, motion } from 'framer-motion'
import React, { createContext, createRef, useContext, useEffect, useState } from 'react'

interface ContextPopupContextInterface {
  hidePopup: () => void
  showPopup: (
    { clientX, clientY }: { clientX: number; clientY: number },
    items: Item[],
    position?: 'left' | 'right' | 'center',
  ) => void
  isShowing: boolean
}

const ContextPopupContext = createContext<ContextPopupContextInterface>({
  hidePopup: () => undefined,
  showPopup: () => undefined,
  isShowing: false,
})

export const ContextPopupProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [items, setItems] = useState<Item[]>([])
  const [show, setShow] = useState(false)
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [position, setPosition] = useState<'left' | 'right' | 'center'>('right')

  const showPopup = (
    { clientX, clientY }: { clientX: number; clientY: number },
    items: Item[],
    position?: 'left' | 'right' | 'center',
  ) => {
    setShow(true)
    setX(clientX)
    setY(clientY)
    setItems(items)
    setPosition(position ?? (clientX < window.innerWidth * (3 / 4) ? 'right' : 'left'))
  }

  const hidePopup = () => {
    setShow(false)
  }

  return (
    <ContextPopupContext.Provider value={{ hidePopup, showPopup, isShowing: show }}>
      {children}
      <ContextPopup position={position} x={x} y={y} show={show} items={items} onClose={() => setShow(false)} />
    </ContextPopupContext.Provider>
  )
}

export const useContextPopup = () => {
  const context = useContext(ContextPopupContext)

  return {
    hidePopup: context.hidePopup,
    showPopup: context.showPopup,
    isShowing: context.isShowing,
  }
}

interface Item {
  icon: string
  text: string
  onClick: () => void
}

interface Props {
  items: Item[]
  x: number
  y: number
  position: 'left' | 'right' | 'center'
  show: boolean
  onClose: () => void
}

function assertIsNode(e: EventTarget | null): e is Node {
  return !(!e || !('nodeType' in e))
}

export const ContextPopup = ({ items, x, y, position, show, onClose }: Props) => {
  const ref = createRef<HTMLDivElement>()

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const node = event.target

      if (!assertIsNode(node)) {
        return
      }

      if (ref.current && !ref.current.contains(node)) {
        onClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose, ref])

  return (
    <AnimatePresence mode="wait" initial={true}>
      {show && (
        <motion.div
          key="popup-context"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ easeIn: 'linear', duration: 0.1 }}
        >
          <Container position={position} x={x} y={y} ref={ref} key={'popup-context'}>
            {items.map((item, i) => (
              <button
                key={item.text + i}
                className="entry"
                onClick={() => {
                  item.onClick()
                  onClose()
                }}
              >
                <IonIcon className="icon" icon={item.icon} />
                <div>{item.text}</div>
              </button>
            ))}
          </Container>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const Container = styled.div<{ x: number; y: number; position: 'right' | 'left' | 'center' }>`
  display: flex;

  z-index: 1000;
  white-space: nowrap;

  flex-direction: column;

  background-color: ${({ theme }) => theme.background};

  position: absolute;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;

  transform: ${({ position }) =>
    position === 'left' ? 'translateX(-100%)' : position === 'right' ? 'translateX(0)' : 'translateX(-50%)'};

  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
  padding: 0.35rem;

  border-radius: 0.25rem;

  > button {
    background-color: transparent;
    border: none;
    outline: none;
  }

  .entry {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: 0.25rem;
    cursor: pointer;
    padding: 0.65rem;
    transition:
      background-color 0.1s ease-in-out,
      color 0.1s ease-in-out;

    font-size: 0.9rem;
    color: ${({ theme }) => theme.onBackgroundSemiLight};

    .icon {
      margin-top: -0.1rem;
      font-size: 0.95rem;
      margin-right: 0.5rem;

      transition:
        background-color 0.1s ease-in-out,
        color 0.1s ease-in-out;
      color: ${({ theme }) => theme.onBackgroundLight};
    }

    :hover {
      color: ${({ theme }) => theme.primaryLight};
      background-color: ${({ theme }) => theme.primaryLighter};

      .icon {
        color: ${({ theme }) => theme.primary};
      }
    }
  }

  > div + div {
    margin-top: 1rem;
  }
`
