'use client'
import styled from '@emotion/styled'

export const MainContainer = styled.div`
  min-height: calc(100vh - 5rem);

  font-size: 1.05rem;
  line-height: 1.6rem;

  background-color: #fcfcfc;
`

export const TopMargin = styled.div`
  margin-top: 5rem;

  @media (max-width: 900px) {
    margin-top: 0;
  }
`
