
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BaseBlock": [
      "BannerBlock",
      "BoardMembersBlock",
      "HeadlineBlock",
      "HeadlineImageBlock",
      "ImageCollectionBlock",
      "TwoColumnBlock"
    ],
    "Block": [
      "BannerBlock",
      "BoardMembersBlock",
      "HeadlineBlock",
      "HeadlineImageBlock",
      "ImageCollectionBlock",
      "TwoColumnBlock"
    ]
  }
};
      export default result;
    