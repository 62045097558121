'use client'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { MenuItem } from './MenuItem'
import { IonIcon } from '@ionic/react'
import { chevronBackSharp, personSharp } from 'ionicons/icons'
import { useMediaPredicate } from 'react-media-hook'
import { CloseButton, HamburgerButton } from '@common/components/IconButtons'
import { useMenuItems } from '@app/site/features/top-menu/use-menu-items'
import { useMenuGroups } from '@app/site/features/top-menu/use-menu-groups'
import Link from 'next/link'
import { useScrollTop } from '@common/hooks/use-scroll-top'

interface InfoPage {
  id: string
  slug: string
  title: string
  group?: string | null
}

export const TopMenu: React.FC<{
  name: string
  infoPages: InfoPage[]
  memberPages: InfoPage[]
  hasErrandsFeature: boolean
  userName: string
}> = ({ userName, name, infoPages, memberPages, hasErrandsFeature }) => {
  const scrollTop = useScrollTop()

  const [showMenu, setShowMenu] = useState<'info' | 'member_info' | 'all' | null>(null)
  const [showSubMenu, setShowSubMenu] = useState<string | null>(null)
  const [subMenuCategory, setSubMenuCategory] = useState<'info' | 'restricted' | null>(null)

  const isMobile = useMediaPredicate('(max-width: 900px)')

  const infoGroups = useMenuGroups(infoPages)
  const memberGroups = useMenuGroups(memberPages)

  const infoItems = useMenuItems(infoPages)
  const memberItems = useMenuItems(memberPages)

  const pageItemsByGroup = () => {
    if (!showSubMenu || !subMenuCategory) return []

    if (subMenuCategory === 'info') {
      return infoGroups[showSubMenu] ?? []
    }

    if (subMenuCategory === 'restricted') {
      return memberGroups[showSubMenu] ?? []
    }

    return []
  }

  const handleCloseMenus = () => {
    setShowMenu(null)
    setShowSubMenu(null)
    setSubMenuCategory(null)
  }

  const handleOpenMenu = (menu: 'info' | 'member_info' | 'all') => {
    setShowMenu(menu)
    setShowSubMenu(null)
    setSubMenuCategory(null)
  }

  const noInfoPages = !infoPages.length && hasErrandsFeature === false
  const noMemberPages = !memberPages.length

  return (
    <>
      <BaseHeight />
      <Base
        onMouseLeave={() => {
          if (isMobile) return

          handleCloseMenus()
        }}
        border={scrollTop > 50}
      >
        <TopContainer>
          <MenuBar className="menu-base menu-items">
            <div>
              <MenuBarItem href="/">Hem</MenuBarItem>
              {!noInfoPages && (
                <MenuBarItem href="#" onMouseEnter={() => !isMobile && setShowMenu('info')}>
                  Information
                </MenuBarItem>
              )}
              {!noMemberPages && (
                <MenuBarItem href="#" onMouseEnter={() => !isMobile && setShowMenu('member_info')}>
                  För boende
                </MenuBarItem>
              )}
            </div>
          </MenuBar>
          <NameTitle className="menu-base" href="/">
            {name}
          </NameTitle>
          <Hamburger className="menu-base">
            <span>
              {!showMenu && <HamburgerButton onClick={() => handleOpenMenu('all')} />}
              {showMenu && <CloseButton onClick={() => handleCloseMenus()} />}
            </span>
          </Hamburger>
          {!userName && !isMobile && (
            <SignInContainer
              className="menu-base"
              onClick={() => {
                handleCloseMenus()
              }}
            >
              <SignInLink href={'/logga-in?to=konto'}>Logga in</SignInLink>
            </SignInContainer>
          )}
          {userName && !isMobile && (
            <SignedInMember className="menu-base" href="/konto" onClick={() => handleCloseMenus()}>
              <span className="name-button">{userName}</span>
              <span className="profile-icon-button">
                <IonIcon icon={personSharp} />
              </span>
            </SignedInMember>
          )}
        </TopContainer>
        <MenuOverlay
          active={!!showMenu}
          submenu={!!showSubMenu}
          onMouseLeave={() => {
            if (isMobile) return

            handleCloseMenus()
          }}
        >
          <div
            className="content-container"
            onMouseLeave={() => {
              if (isMobile) return
              handleCloseMenus()
            }}
          >
            <div
              className="content"
              onMouseLeave={() => {
                if (isMobile) return

                handleCloseMenus()
              }}
            >
              <div className="first-half">
                {isMobile && (
                  <SignInMobile
                    className="menu-base"
                    href={userName ? '/konto' : '/logga-in?to=konto'}
                    onClick={() => handleCloseMenus()}
                  >
                    {userName || 'Logga in'}
                  </SignInMobile>
                )}
                {!!infoPages.length && (showMenu === 'info' || showMenu === 'all') && (
                  <MenuHeadline>Allmänt</MenuHeadline>
                )}
                {infoItems.map((item) => {
                  return (
                    <MenuItem
                      key={item.id ?? item.title}
                      subItem={item.isGroup}
                      hidden={showMenu !== 'info' && showMenu !== 'all'}
                      underline={showSubMenu === item.title && subMenuCategory === 'info' ? true : undefined}
                      text={item.title}
                      href={item.isGroup ? '#' : '/info/' + item.slug}
                      onMouseEnter={() => {
                        if (isMobile) return

                        if (item.isGroup) {
                          setShowSubMenu(item.title)
                          setSubMenuCategory('info')
                        } else {
                          setShowSubMenu(null)
                        }
                      }}
                      onClick={() => {
                        if (!item.isGroup) {
                          handleCloseMenus()
                          return
                        }
                        setShowSubMenu(item.title)
                        setSubMenuCategory('info')
                      }}
                    />
                  )
                })}
                {hasErrandsFeature && (showMenu === 'info' || showMenu === 'all') && (
                  <>
                    <div style={{ marginTop: '2rem' }} />
                    <MenuHeadline>Kontakt</MenuHeadline>
                    <MenuItem
                      subItem={false}
                      underline={false}
                      text="Kontaktformulär"
                      href="/kontakt"
                      onClick={() => {
                        handleCloseMenus()
                      }}
                    />
                  </>
                )}

                {!!infoPages.length && !!memberPages.length && showMenu === 'all' && (
                  <div style={{ marginTop: '2rem' }} />
                )}
                {!!memberPages.length && (showMenu === 'member_info' || showMenu === 'all') && (
                  <MenuHeadline>För boende</MenuHeadline>
                )}
                {memberItems.map((item) => {
                  return (
                    <MenuItem
                      key={item.id ?? item.title}
                      subItem={item.isGroup}
                      hidden={showMenu !== 'member_info' && showMenu !== 'all'}
                      underline={showSubMenu === item.title && subMenuCategory === 'restricted' ? true : undefined}
                      text={item.title}
                      href={item.isGroup ? '#' : '/info/' + item.slug}
                      onMouseEnter={() => {
                        if (isMobile) return

                        if (item.isGroup) {
                          setShowSubMenu(item.title)
                          setSubMenuCategory('restricted')
                        } else {
                          setShowSubMenu(null)
                        }
                      }}
                      onClick={() => {
                        if (!item.isGroup) {
                          handleCloseMenus()
                          return
                        }
                        setShowSubMenu(item.title)
                        setSubMenuCategory('restricted')
                      }}
                    />
                  )
                })}
              </div>
              <div className="second-half">
                <div className="second-half-container">
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div style={{ padding: '0 0.5rem', cursor: 'pointer' }} onClick={() => setShowSubMenu(null)}>
                    <IonIcon icon={chevronBackSharp} style={{ fontSize: '0.85rem', marginTop: '0.15rem' }} />
                  </div>
                  <div style={{ width: '100%' }}>
                    <MenuHeadline>{showSubMenu}</MenuHeadline>
                    {pageItemsByGroup().map((page) => {
                      return (
                        <MenuItem
                          key={page.id}
                          subItem={false}
                          text={page.title}
                          underline={false}
                          href={'/info/' + page.slug}
                          onClick={() => handleCloseMenus()}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MenuOverlay>
      </Base>
    </>
  )
}

const BaseHeight = styled.div`
  height: 2rem;
`

const Base = styled.div<{
  border: boolean
}>`
  z-index: 20003;
  background-color: #fcfcfc;

  ${({ border, theme }) => (border ? `border-bottom: 1px solid ${theme.onBackgroundLightest};` : '')}
  & a {
    color: ${({ theme }) => theme.onBackground};
  }

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 0.75rem;

  @media only screen and (min-width: 600px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media only screen and (min-width: 800px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  @media only screen and (min-width: 1000px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  .menu-base {
    z-index: 20010;

    & a {
      color: ${({ theme }) => theme.onBackground};
    }

    & a:hover {
      color: ${({ theme }) => theme.primaryLight};
    }
  }

  .menu-items {
    display: block;
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
`

const MenuOverlay = styled.div<{
  active: boolean
  submenu: boolean
}>`
  pointer-events: ${({ active }) => (active ? undefined : 'none')};

  z-index: 20001;
  position: fixed;
  top: 0;
  left: 0;

  transition: all 0.2s ease;
  opacity: ${({ active }) => (active ? 1 : 0)};

  width: 100vw;
  height: 5rem;

  .content-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20009;

    background-color: rgba(0, 0, 0, 0.2);

    width: 100vw;
    height: 100vh;

    overflow-y: scroll;

    .content {
      z-index: 20010;

      padding-top: 6.5rem;
      padding-bottom: 3rem;

      width: 100vw;

      @media (max-width: 600px) {
        padding-top: 4.5rem;
        height: fit-content;
      }

      display: flex;
      flex-direction: row;

      background-color: #fcfcfc;

      .first-half {
        padding: 2rem 0 2rem 10rem;

        @media only screen and (min-width: 600px) {
          padding-left: 2rem;
        }

        @media only screen and (min-width: 800px) {
          padding-left: 5rem;
        }

        @media only screen and (min-width: 1000px) {
          padding-left: 10%;
        }

        min-width: 30rem;

        @media (max-width: 900px) {
          display: ${({ submenu }) => (submenu ? 'none' : 'block')};

          min-width: 100vw;
          width: 100vw;

          padding: 2rem 2rem 2rem;
        }
      }

      .second-half-container {
        display: flex;
        flex-direction: row;
      }

      .second-half {
        padding: 2rem 10rem 2rem 2rem;

        min-width: 30rem;

        @media (max-width: 900px) {
          display: ${({ submenu }) => (submenu ? 'block' : 'none')};
          min-width: 100vw;
          width: 100vw;

          overflow-y: auto;

          padding: 2rem 2rem 2rem;
        }

        transition: all 0.2s ease;
        background-color: #fcfcfc;

        > * {
          transition: all 0.2s ease;
          opacity: ${({ submenu }) => (submenu ? 1 : 0)};
        }
      }
    }
  }
`

const MenuBarItem = styled(Link)`
  cursor: pointer;
  white-space: nowrap;

  transition: border-bottom 0.2s ease;

  border-bottom: 1px solid transparent;

  margin-right: 2rem;

  :hover {
    border-bottom: 1px solid ${({ theme }) => theme.primaryLight};
  }
`

const SignInMobile = styled(Link)`
  display: none;

  margin-bottom: 2.5rem;

  text-decoration: underline;

  @media (max-width: 800px) {
    display: flex;
  }
`

const SignInLink = styled(Link)`
  cursor: pointer;

  width: fit-content;

  color: ${({ theme }) => theme.primaryLight};
  margin-left: auto;

  border-bottom: 1px solid ${({ theme }) => theme.onBackground};
  transition: all 0.2s ease;
  :hover {
    border-color: ${({ theme }) => theme.primaryLight};
  }
`

const SignInContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const SignedInMember = styled(Link)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s ease;

  span {
    margin-left: auto;
  }

  cursor: pointer;

  .profile-icon-button {
    font-size: 1.35rem;
    display: none;

    @media (max-width: 800px) {
      display: flex;
    }

    :hover {
      color: ${({ theme }) => theme.primaryLight};
    }
  }

  .name-button {
    display: inline-block;

    @media (max-width: 800px) {
      display: none;
    }

    border-bottom: 1px solid ${({ theme }) => theme.primaryLight};

    color: ${({ theme }) => theme.primaryLight};

    :hover {
      color: ${({ theme }) => theme.primary};
      border-color: ${({ theme }) => theme.primary};
    }
  }
`

const NameTitle = styled(Link)`
  cursor: pointer;
  font-size: 1.25rem;

  transition: all 0.2s ease;

  font-weight: bold;

  flex: 1;

  white-space: nowrap;
  text-align: center;

  padding: 0.4rem 0.8rem;

  @media (max-width: 800px) {
    font-size: 1.15rem;
    text-align: left;

    padding: 0;
  }

  :hover {
    color: ${({ theme }) => theme.onBackgroundSemiLight};
  }
`

const MenuBar = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: row;
  z-index: 20010;

  > div {
    display: flex;
    flex-direction: row;

    margin-right: auto;
  }

  @media (max-width: 800px) {
    display: none;
  }
`

const Hamburger = styled.div`
  span {
    display: flex;
    flex-direction: row;

    margin-right: auto;
  }

  display: none;
  @media only screen and (max-width: 900px) {
    display: block;
  }
`

const MenuHeadline = styled.div`
  border-bottom: 1px solid gainsboro;
  font-size: 0.85rem;
  margin-bottom: 1rem;

  color: rgb(40, 40, 40);
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    justify-content: flex-start;
  }

  width: 100%;

  z-index: 20002;
  background-color: ${({ theme }) => theme.background};

  padding: 0.5rem 0 0.5rem;

  @media (min-width: 600px) {
    padding-top: 0.5rem;
  }
`
