import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { TOKEN_HANDLE } from '../hooks/use-token'
import { onError } from '@apollo/client/link/error'
import introspection from './generated/possible-types'

export const httpLink = (headers?: Record<string, string>) =>
  createHttpLink({
    uri:
      process.env.NODE_ENV === 'production'
        ? 'https://goldfish-app-m6b6w.ondigitalocean.app/graphql'
        : 'http://localhost:3000/graphql',
    headers,
  })

export const authLink = setContext(async (_, { headers }) => {
  const token = document.cookie.replace(/(?:(?:^|.*;\s*)b_at\s*=\s*([^;]*).*$)|^.*$/, '$1')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    const unauthenticated = networkError.message.includes('401')

    if (unauthenticated) {
      localStorage.removeItem(TOKEN_HANDLE)

      const event = new Event('onSignOut')
      document.dispatchEvent(event)
    }
  }
})

export const client = new ApolloClient({
  connectToDevTools: false,
  link: from([authLink, errorLink, httpLink()]),
  cache: new InMemoryCache({
    possibleTypes: introspection.possibleTypes,
    typePolicies: {
      Me: {
        keyFields: [],
      },
      UploadedFileV2: {
        keyFields: ['key'],
      },
      NotificationSettings: {
        keyFields: false,
      },
      BookingCategorySettings: {
        keyFields: false,
        merge: true,
      },
      MeetingTimeslot: {
        fields: {
          votes: {
            merge(existing, incoming: any[]) {
              if (Array.isArray(incoming)) {
                return [...incoming]
              }

              return [...existing]
            },
          },
        },
      },
    },
  }),
})
