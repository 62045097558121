import { useMemo } from 'react'

interface InfoPage {
  id: string
  slug: string
  title: string
  group?: string | null
}

export const useMenuGroups = (pages: InfoPage[]) => {
  return useMemo(
    () =>
      pages.reduce<Record<string, InfoPage[]>>((result, page) => {
        if (!page.group) return result

        if (!result?.[page.group]?.length) {
          result[page.group] = [page]
        } else {
          result[page.group] = [...result[page.group], page]
        }

        return result
      }, {}),
    [pages],
  )
}
