import React from 'react'
import { IonIcon } from '@ionic/react'
import { chevronForwardSharp } from 'ionicons/icons'
import styled from '@emotion/styled'
import Link, { LinkProps } from 'next/link'

export const MenuItem: React.FC<
  {
    subItem?: boolean
    text: string
    underline?: boolean
    hidden?: boolean
  } & LinkProps
> = ({ subItem, text, underline, hidden, ...props }) => {
  return (
    <Container hidden={hidden ?? false} pushArrow={underline ? 1 : 0}>
      <StyledLink underline={underline ? 1 : 0} {...props}>
        {text}
      </StyledLink>
      {subItem && <IonIcon className="arrow-icon" icon={chevronForwardSharp} />}
    </Container>
  )
}

const StyledLink = styled(Link)<{ underline?: number }>`
  transition: all 0.2s ease;
  border-bottom: 1px solid ${({ theme, underline }) => (underline ? theme.onBackground : 'transparent')};

  :hover {
    border-bottom: 1px solid ${({ theme }) => theme.onBackground};
  }
`

const Container = styled.div<{ hidden: boolean; pushArrow: number }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};

  align-items: center;

  padding: 0.5rem 0.3rem;

  background-color: transparent;
  border: none;
  font-size: 1rem;

  color: ${({ theme }) => theme.onBackground};
  text-align: left;
  font-family: inherit;

  .arrow-icon {
    transition: all 0.2s ease;

    font-size: 0.8rem;
    margin-left: ${({ pushArrow }) => (pushArrow ? '0.5rem' : '0.25rem')};
  }

  :hover {
    .arrow-icon {
      margin-left: 0.5rem;
    }
  }
`
