import { css } from '@emotion/react'

export const SiteResponsivePadding = css`
  padding: 0 0.25rem;

  @media only screen and (min-width: 600px) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  @media only screen and (min-width: 800px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  @media only screen and (min-width: 1000px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`
