import { useCookies } from 'react-cookie'
import { useMemo } from 'react'

export const TOKEN_HANDLE = 'b_at'

export const parseCustomDomain = (host: string) => {
  const hostWithoutPort = host.split(':')[0]
  const parts = hostWithoutPort.split('.')

  if (parts.length === 2) {
    return parts[0] + '.' + parts[1]
  }

  if (parts.length === 3 && parts[0] === 'www') {
    return parts[1] + '.' + parts[2]
  }

  return null
}

export const useToken = () => {
  const domain = useMemo(() => {
    let maybeCustomDomain = ''
    if (typeof window !== 'undefined') {
      maybeCustomDomain = parseCustomDomain(window.location.host) ?? ''
    }

    if (maybeCustomDomain) {
      return `.${maybeCustomDomain}`
    }

    if (process.env.NODE_ENV !== 'production') {
      return '.localhost.test'
    }

    return '.booet.se'
  }, [])

  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_HANDLE])

  const handleSetToken = (newToken: string) => {
    const now = new Date()
    const expires = new Date(now.setHours(now.getHours() + 6))

    setCookie(TOKEN_HANDLE, newToken, { expires, domain, path: "/" })
  }

  const handleClearToken = () => {
    removeCookie(TOKEN_HANDLE, { domain })
  }

  const handleGetToken = (): string | null => {
    return cookies.b_at ?? null
  }

  return { setToken: handleSetToken, clearToken: handleClearToken, token: handleGetToken }
}
