'use client'
import { ReactNode, StrictMode } from 'react'
import { client } from '@common/graphql/client'
import { ScrollToTop } from './main-site/_components/ScrollToTop'
import { theme } from '../theme'
import { ContextPopupProvider } from '@common/components/ContextPopup'
import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider'
import { ThemeProvider } from '@emotion/react'
import { DndProvider } from 'react-dnd/dist/core/DndProvider'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { CookiesProvider } from 'react-cookie'
import { Toaster } from 'react-hot-toast'

export const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <StrictMode>
      <ApolloProvider client={client}>
        <ScrollToTop />
        <ThemeProvider theme={theme}>
          <DndProvider backend={HTML5Backend}>
            <CookiesProvider>
              <ContextPopupProvider>{children}</ContextPopupProvider>
              <Toaster
                toastOptions={{ style: { borderRadius: 0, padding: '1rem 2rem' } }}
                containerStyle={{ zIndex: 99999 }}
              />
            </CookiesProvider>
          </DndProvider>
        </ThemeProvider>
      </ApolloProvider>
    </StrictMode>
  )
}
