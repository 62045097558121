'use client'
import styled from '@emotion/styled'
import { SiteResponsivePadding } from '@app/site/common/components/SiteResponsivePadding'

export const Footer = styled.div<{ light?: boolean }>`
  ${SiteResponsivePadding};

  padding-top: 5em;
  padding-bottom: 4rem;

  background-color: ${({ light }) => (light ? '#f4f6f8' : '#222')};

  > div {
    display: flex;
    flex-direction: column;

    width: 100%;

    > div {
      display: block;
      font-size: 1rem;
      color: ${({ theme }) => theme.onBackgroundLight};

      line-height: 1.5rem;
    }
  }

  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  color: ${({ theme }) => theme.onBackgroundLight};

  h5 {
    display: block;
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
    color: #5a5a5a;
  }

  a {
    display: block;
    font-size: 1rem;
    color: ${({ theme }) => theme.onBackgroundLight};
  }

  a + a {
    margin-top: 0.45rem;
  }

  .logo {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @media (max-width: 800px) {
      margin-top: 4rem;
      align-items: center;
    }

    margin: 0;
    padding: 0;

    width: 100%;

    a {
      font-weight: 800;
      font-size: 1.15rem;
      margin: 0;

      transition: color 200ms ease-in-out;
      :hover {
        color: ${({ theme }) => theme.primaryLight};
      }
    }
  }
`
