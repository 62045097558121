
import { IonIcon } from '@ionic/react'
import { closeSharp, menuSharp } from 'ionicons/icons'
import styled from '@emotion/styled'

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  color: ${({ theme }) => theme.onBackground};

  :hover {
    color: ${({ theme }) => theme.onBackgroundLight};
  }
`

export const HamburgerButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <IconWrapper>
      <IonIcon
        onTouchEnd={(e) => {
          onClick()
          e.preventDefault()
        }}
        onClick={onClick}
        icon={menuSharp}
        style={{ fontSize: '1.5rem' }}
      />
    </IconWrapper>
  )
}

export const CloseButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <IconWrapper>
      <IonIcon
        onTouchEnd={(e) => {
          onClick()
          e.preventDefault()
        }}
        onClick={onClick}
        icon={closeSharp}
        style={{ fontSize: '1.5rem' }}
      />
    </IconWrapper>
  )
}
