export const theme = {
  surface: '#f6f9ff',
  surfaceContrast: '#fcfcfc',
  background: '#fafafa',
  onBackground: '#141414',
  onBackgroundSemiDark: '#282828',
  onBackgroundSemiLight: '#424242',
  onBackgroundLight: '#969696',
  onBackgroundLighter: '#d3d3d3',
  onBackgroundLightest: '#ececec',
  error: '#DC143C',
  errorLight: '#f86885',
  errorLighter: '#ffcbd7',
  errorLightest: '#ffeef3',
  success: '#00b33f',
  successLight: '#2fce6c',
  successLighter: '#cdffd8',
  warning: '#f67546',
  yellow: '#f1c125',
  primary: '#1556ff',
  primaryLight: '#4176ff',
  primaryLighter: '#e9effe',
  primaryLightest: '#edf1fb',
  primaryDark: '#2733a5',
  onPrimary: 'white',
  secondary: '#ab17d8',
  secondaryLight: '#ad3dce',
  secondaryLighter: '#f6d6ff',
  secondaryLightest: '#fdf2ff',
}

type ThemeConfig = typeof theme
declare module '@emotion/react' {
  // eslint-disable-next-line
  export interface Theme extends ThemeConfig {}
}
